/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {MultiLangCode} from "./../../../src/components/MultiLangCode";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    br: "br",
    h3: "h3",
    div: "div",
    span: "span",
    em: "em",
    h4: "h4",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Sieve_of_Eratosthenes"
  }, "sieve of Eratosthenes"), " finds all ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Prime_number"
  }, "prime numbers"), " up to a given limit."), "\n", React.createElement(_components.h2, {
    id: "method"
  }, "Method"), "\n", React.createElement(_components.p, null, "The algorithm starts out by assuming all numbers are prime, and marking them as such.\nAt the end of the algorithm, only prime numbers up to an upper limit will still be marked."), "\n", React.createElement(_components.p, null, "The number 1 is a special case, so we start off by unmarking it."), "\n", React.createElement(_components.p, null, "Then we go through the numbers one by one.\nFor every non-prime number we find, skip to the next number."), "\n", React.createElement(_components.p, null, "If a number is still marked as prime when we get to it, that means it is prime.", React.createElement(_components.br), "\n", "Before moving on to the next number, we first unmark every multiple of the found prime."), "\n", React.createElement(_components.p, null, "Those multiples can be divided through the prime number we just found, so by definition isn’t prime."), "\n", React.createElement(_components.p, null, "We repeat this process until we reach the upper limit."), "\n", React.createElement(_components.p, null, "Every number that is still marked as prime, is truly prime."), "\n", React.createElement(_components.h2, {
    id: "optimizations"
  }, "Optimizations"), "\n", React.createElement(_components.p, null, "By using some math we can do significantly less work while still getting the same result."), "\n", React.createElement(_components.h3, {
    id: "repeat-until-the-square-root"
  }, "Repeat until the square root"), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: Only check numbers up to the square root of the upper limit.", React.createElement(_components.br), "\n", "After that, every number up to that limit will be accurately marked, because math is cool.")), "\n", React.createElement(_components.p, null, "While iterating through all numbers, we can stop at the square root of the upper limit."), "\n", React.createElement(_components.p, null, "Any non-prime can be expressed as the product of 2 numbers that are not 1 or itself."), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "n = a * b"), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "a"), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "b"), " are ", React.createElement(_components.em, null, "factors"), " of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "n"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "n = \\sqrt{n} * \\sqrt{n}"), ", so one factor has to be less than or equal to ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\sqrt{n}"), " while the other is greater than or equal to that square root."), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "a \\leq \\sqrt{n} \\leq b"), "\n", React.createElement(_components.p, null, "Up to any number ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "n"), ", all multiples of a number bigger than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\sqrt{n}"), " must have a factor smaller than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\sqrt{n}"), ".\nAs a result that multiple will already be unmarked."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The factors can both be identical to ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\sqrt{n}"), ".", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "49"), " for example")), "\n", React.createElement(_components.p, null, "This means that all the non-primes ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\geq \\sqrt{limit}"), " will be unmarked in the process of checking every number ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "\\leq \\sqrt{limit}"), "."), "\n", React.createElement(_components.h4, {
    id: "example"
  }, "Example"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "\\sqrt{21} = 4.58"), "\n", React.createElement(_components.p, null, "Any number up to ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "21"), " that is a multiple of a number larger than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "4.58"), " will have a factor smaller than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "4.58"), "."), "\n", React.createElement(_components.p, null, "Because ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "18"), " is a number up to ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "21"), ".", React.createElement(_components.br), "\n", "It is also a multiple of a number that is bigger than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "4.58"), "."), "\n", React.createElement(_components.p, null, "That means a factor of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "18"), " must be smaller than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "4.58"), "."), "\n", React.createElement(_components.p, null, "That checks out, ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "3"), " is a factor!"), "\n", React.createElement(_components.p, null, "Because ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "3"), " is a factor of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "18"), ".\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "18"), " was unmarked while going through multiples when ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "3"), " was the number the algorithm was unmarking multiples for!"), "\n", React.createElement(_components.h3, {
    id: "start-unmarking-at-the-square"
  }, "Start unmarking at the square"), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: Start unmarking multiples of a number at that number squared.", React.createElement(_components.br), "\n", "All multiples below are already unmarked, because math is cool.")), "\n", React.createElement(_components.p, null, "During the step the algorithm unmarks all multiples of a number.\nWe can start unmarking at that number squared."), "\n", React.createElement(_components.p, null, "Every smaller multiple was already unmarked in a previous iteration."), "\n", React.createElement(_components.p, null, "Why?"), "\n", React.createElement(_components.p, null, "A multiple can be written as a multiplier times a number."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "m = multiple")), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "k = multiplier")), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "p = prime")), "\n"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "m = k * p"), "\n", React.createElement(_components.p, null, "The number that is now ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "p"), ", was previously ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "k"), " for every smaller prime number."), "\n", React.createElement(_components.p, null, "Because ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "k * p = p * k"), ", every multiple smaller than ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "p * p"), " has already been unmarked in a previous iteration."), "\n", React.createElement(_components.h4, {
    id: "example-1"
  }, "Example"), "\n", React.createElement(_components.p, null, "If our current detected prime, ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "p =  5"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "5"), " was previously the multiplier for every smaller prime number."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "5 * 2"), " was unmarked when ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "p"), " was ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "2"), ", we don’t need to calculate ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "2 * 5")), "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "5 * 3"), " was unmarked when ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "p"), " was ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "3"), ", we don’t need to calculate ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "3 * 5")), "\n"), "\n", React.createElement(_components.h2, {
    id: "step-by-step-in-code"
  }, "Step by step in code"), "\n", React.createElement(_components.p, null, "The goal is to write a function that returns a list of prime numbers, up to ", React.createElement(_components.code, null, "upper_bound"), "."), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "I named that variable ", React.createElement(_components.code, null, "optimus_prime"), " while writing code for this post, because I thought that was funny")), "\n", React.createElement(_components.p, null, "We initialise a list of booleans that is 1 bigger than the given ", React.createElement(_components.code, null, "upper_bound"), " and call it ", React.createElement(_components.code, null, "sieve"), ".\nThese booleans tell us if the number at that index is prime or not. (", React.createElement(_components.code, null, "True"), " for prime, ", React.createElement(_components.code, null, "False"), " for not)"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    hl: "3"
  }, "def primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n")), "\n", React.createElement(_components.p, null, "Smart people decided programmers start counting at 0, so that’s why that list is 1 bigger than ", React.createElement(_components.code, null, "upper_bound"), ".\nIt’s also the reason why we have to unmark the index 0 along with the index 1 before we start our loop."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    hl: "6-7"
  }, "def primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n\n  # 0 and 1 are not prime\n  sieve[0] = False\n  sieve[1] = False\n")), "\n", React.createElement(_components.p, null, "This works out perfectly, because now every index exactly matches the number it represents."), "\n", React.createElement(_components.p, null, "You want to know if the number 69 is prime? The boolean at index 69 will tell you. Nice!"), "\n", React.createElement(_components.p, null, "Loop over every number, starting at 2 and ", React.createElement(_components.a, {
    href: "#square-root"
  }, "ending at the square root of ", React.createElement(_components.code, null, "upper_bound")), ".\nInside the loop, index ", React.createElement(_components.code, null, "sieve"), " with that number."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    hl: "14,16"
  }, "import math\n\ndef primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n\n  # 0 and 1 are not prime\n  sieve[0] = False\n  sieve[1] = False\n\n  # iterate up to square root of upper_bound\n  # reason: if one factor of num is bigger than sqrt(upper_bound),\n  # an other factor _must_ be smaller than sqrt(upper_bound)\n  for num in range(2, math.floor(math.sqrt(upper_bound)) + 1):\n    # if sieve[num] is true, then num is prime\n    if sieve[num]:\n")), "\n", React.createElement(_components.p, null, "If the boolean at that location is ", React.createElement(_components.code, null, "True"), ", the number is prime and we unmark every multiple before moving on to the next step of our loop."), "\n", React.createElement(_components.p, null, "Do this by skip counting.\n", React.createElement(_components.a, {
    href: "#start-unmarking-at-the-square"
  }, "Start at the number squared"), " and add the number until you hit ", React.createElement(_components.code, null, "upper_bound"), ".", React.createElement(_components.br), "\n", "For every encountered multiple, set ", React.createElement(_components.code, null, "sieve"), " at that number’s index to ", React.createElement(_components.code, null, "False"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    hl: "20-21"
  }, "import math\n\ndef primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n\n  # 0 and 1 are not prime\n  sieve[0] = False\n  sieve[1] = False\n\n  # iterate up to square root of upper_bound\n  # reason: if one factor of num is bigger than sqrt(upper_bound),\n  # an other factor _must_ be smaller than sqrt(upper_bound)\n  for num in range(2, math.floor(math.sqrt(upper_bound)) + 1):\n    # if sieve[num] is true, then num is prime\n    if sieve[num]:\n      # unmark all multiples\n      # start unmarking at num squared\n      # every smaller multiple has already been unmarked in previous iterations\n      for multiple in range(num ** 2, upper_bound + 1, num):\n        sieve[multiple] = False\n")), "\n", React.createElement(_components.p, null, "At the end of the outer loop, ", React.createElement(_components.code, null, "sieve"), " will be full of booleans corresponding to the primeness of every possible index to that list."), "\n", React.createElement(_components.p, null, "Use your favourite method to loop over a list while also getting the index, put the indexes with a ", React.createElement(_components.code, null, "true"), " into a new list, and presto, primes."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    hl: "24"
  }, "import math\n\ndef primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n\n  # 0 and 1 are not prime\n  sieve[0] = False\n  sieve[1] = False\n\n  # iterate up to square root of upper_bound\n  # reason: if one factor of num is bigger than sqrt(upper_bound),\n  # an other factor _must_ be smaller than sqrt(upper_bound)\n  for num in range(2, math.floor(math.sqrt(upper_bound)) + 1):\n    # if sieve[num] is true, then num is prime\n    if sieve[num]:\n      # unmark all multiples\n      # start unmarking at num squared\n      # every smaller multiple has already been unmarked in previous iterations\n      for multiple in range(num ** 2, upper_bound + 1, num):\n        sieve[multiple] = False\n\n  # sieve is done, turn `True` into numbers\n  return [idx for idx, mark in enumerate(sieve) if mark]\n")), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "The returned value is a list of prime numbers, starting at 2, and ending in the last prime up to ", React.createElement(_components.code, null, "upper_bound"), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "primes_up_to(16)"), " returns ", React.createElement(_components.code, null, "[2, 3, 5, 7, 11, 13]"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "primes_up_to(17)"), " returns ", React.createElement(_components.code, null, "[2, 3, 5, 7, 11, 13, 17]"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "primes_up_to(18)"), " returns ", React.createElement(_components.code, null, "[2, 3, 5, 7, 11, 13, 17]"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "primes_up_to(19)"), " returns ", React.createElement(_components.code, null, "[2, 3, 5, 7, 11, 13, 17, 19]"), "."), "\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(MultiLangCode, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "sieve.rs",
    numberLines: true
  }, "pub fn primes_up_to(upper_bound: usize) -> Vec<usize> {\n    // initialise sieve that marks all numbers as prime\n    let mut sieve = vec![true; upper_bound + 1];\n\n    // 0 and 1 are not prime\n    sieve[0] = false;\n    sieve[1] = false;\n\n    // iterate up to square root of upper_bound\n    // reason: if one factor of num is bigger than sqrt(upper_bound),\n    // an other factor _must_ be smaller than sqrt(upper_bound)\n    for num in 2..=(upper_bound as f64).sqrt() as usize + 1 {\n        // if sieve[num] is true, then num is prime\n        if sieve[num] {\n            // unmark all multiples\n            // start unmarking at num squared\n            // every smaller multiple has already been unmarked in previous iterations\n            for multiple in (num * num..=upper_bound).step_by(num) {\n                sieve[multiple] = false;\n            }\n        }\n    }\n\n    // sieve is done, turn `true` into numbers\n    sieve\n        .iter()\n        .enumerate()\n        .filter_map(|(idx, mark)| match mark {\n            true => Some(idx),\n            false => None,\n        })\n        .collect()\n}\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript",
    title: "sieve.js",
    numberLines: true
  }, "function primesUpTo(upperBound) {\n  // initialise sieve that marks all numbers as prime\n  const sieve = Array.from({ length: upperBound + 1 }, () => true);\n\n  // 0 and 1 are not prime\n  sieve[0] = false;\n  sieve[1] = false;\n\n  // iterate up to square root of upperBound\n  // reason: if one factor of num is bigger than sqrt(upperBound),\n  // an other factor _must_ be smaller than sqrt(upperBound)\n  for (let num = 2; num <= Math.sqrt(upperBound) + 1; num++) {\n    // if sieve[num] is true, then num is prime\n    if (sieve[num]) {\n      // unmark all multiples\n      // start unmarking at num squared\n      // every smaller multiple has already been unmarked in previous iterations\n      for (let multiple = num ** 2; multiple <= upperBound; multiple += num) {\n        sieve[multiple] = false;\n      }\n    }\n  }\n\n  // sieve is done, turn `true` into numbers\n  const primes = [];\n  for (const [idx, mark] of sieve.entries()) {\n    mark && primes.push(idx);\n  }\n\n  return primes;\n}\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "sieve.py",
    numberLines: true
  }, "import math\n\ndef primes_up_to(upper_bound):\n  # initialise sieve that marks all numbers as prime\n  sieve = [True] * (upper_bound + 1)\n\n  # 0 and 1 are not prime\n  sieve[0] = False\n  sieve[1] = False\n\n  # iterate up to square root of upper_bound\n  # reason: if one factor of num is bigger than sqrt(upper_bound),\n  # an other factor _must_ be smaller than sqrt(upper_bound)\n  for num in range(2,math.floor(math.sqrt(upper_bound)) + 1):\n    # if sieve[num] is true, then num is prime\n    if sieve[num]:\n      # unmark all multiples\n      # start unmarking at num squared\n      # every smaller multiple has already been unmarked in previous iterations\n      for multiple in range(num**2, upper_bound + 1, num):\n        sieve[multiple] = False\n\n  # sieve is done, turn `True` into numbers\n  return [idx for idx, mark in enumerate(sieve) if mark]\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
